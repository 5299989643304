<template>
  <div>
    <Spinner v-show="showInProgressSection" message="Reactivation is in progress. Please wait..."/>
    <DoodleMessage class="has-text-grey-dark" :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
    <DoodleMessage class="has-text-grey-dark" message="Reactivation successful. Thanks for Using JDoodle.com Compiler APIs." message-class="has-text-danger" v-show="doodleForm.completed"/>
    <div class="has-text-centered" v-if="showMainSection">
      <div class="has-text-centered has-text-grey-dark has-text-weight-bold">Are you sure want to re-activate your subscription?</div>
      <div class="api-subscription-detail">
        <div class="notes">
          <ul>
            <li>Your current plan will be reactivated, your plan will go to the state where it was when canceled.</li>
            <li>Changes you made to current plan before cancellation (e.g downgrade starting from end of current billing period) will be reactivated as well.</li>
            <li>Read <a href="/compiler-api/docs" target="_blank">API FAQ</a> for more details</li>
          </ul>
        </div>
        <div class="refresh-button-div">
          <button class="button is-danger has-text-weight-bold" @click="reactivate">Re-activate Subscription</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import Spinner from '../Utils/Spinner'
import DoodleMessage from '../Utils/DoodleMessage'
import { eventBus } from '../../assets/javascript/event-bus'

export default {
  name: 'apiReactivateSubscription',
  components: { DoodleMessage, Spinner },
  mixins: [formMixin],
  props: ['tabProperties'],
  methods: {
    reactivate () {
      this.executeAPIWitoutValiation({
        url: '/api/compiler-api/reactivateSubscription',
        successAction: (data) => {
          this.$_.delay(() => { eventBus.$emit('APIPlanChanged') }, 5000)
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.API,
        jdaEvent: 'reactivate',
        jdaLabel: ''
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .notes {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 0.85em;
    padding: 5px;
  }

  .refresh-button-div {
    margin-top: 40px;
  }
</style>
